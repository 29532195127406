import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { t } from "i18next";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import isToday from 'dayjs/plugin/isToday';

export class FormatDateTime {
    static registerLocale(): void {
        dayjs.extend(calendar);
        dayjs.extend(relativeTime);
        dayjs.extend(isToday);
        dayjs.extend(updateLocale);
        dayjs.extend(localizedFormat);

        const relativeTimeFormatting = {
            past: `%s ${t('ago')}`,
            s: `${t('a few seconds')}`,
            m: `${t('a minute')}`,
            mm: `%d ${t('minutes')}`,
            h: `${t('an hour')}`,
            hh: `%d ${t('hours')}`
        };
        const calenderFormat = {
            lastDay: `[${t('Yesterday at')}] LT`,
            lastWeek: `[${t('Last')}] dddd [${('at')}] LT`,
            sameElse: 'LL'
        };

        dayjs.updateLocale('en', {
            calendar: calenderFormat,
            relativeTime: relativeTimeFormatting
        });
    }
    public static convert(dateTime: Date): string {
        this.registerLocale();
        if (dayjs(dateTime).isToday())
            return dayjs(dateTime).fromNow();
        else
            return dayjs(dateTime).calendar(dayjs());
    }
    public static convertDuration(timeString: string): string {
        const [hours, minutes, secondsAndMilliseconds] = timeString.split(':');
        const [seconds] = secondsAndMilliseconds.split('.');
        const parsedHours = parseInt(hours, 10);
        const parsedMinutes = parseInt(minutes, 10);
        const parsedSeconds = parseInt(seconds, 10);
        let parsedString = '';
        if (parsedHours > 0)
            parsedString += `${parsedHours} ${t('hours')} `;
        if (parsedMinutes > 0)
            parsedString += `${parsedMinutes} ${t('minutes')} `;
        if (parsedSeconds > 0)
            parsedString += `${parsedSeconds} ${t('seconds')}`;
        return parsedString;
    }
}