import { Features } from "../config/Features";
import { formatCaseTitle, isReadonly, MapISharePointFileResponseToISharepointDocumentItem } from "../helpers/Mapper";
import { ChannelAutomaticArchivingInfo, IFileListItem, ISharepointFileResponse } from "../model/model";

export enum FilesActionType {
    RefreshFilesFromTeams = "REFRESH_FILES_FROM_TEAMS",
    RefreshArchivingStatus = "REFRESH_ARCHIVING_STATUS",
    AddImportedFiles = "ADD_IMPORTED_FILES",
    RemoveSyncConflict = "REMOVE_SYNC_CONFLICT",
    SetArchivingStatusChecked = "SET_ARCHIVING_STATUS_CHECKED",
    SetChannelAutomaticArchivingInfo = "SET_CHANNEL_AUTOMATIC_ARCHIVING_INFO"
}

export interface IFilesState {
    files: IFileListItem[];
}

export interface IFilesPayload {
    folder: IFileListItem,
    data?: IFileListItem[] | ISharepointFileResponse[],
    channelInfo?: ChannelAutomaticArchivingInfo | ISharepointFileResponse
}

export interface IFilesAction {
    type: FilesActionType;
    payload?: IFilesPayload;
}

function FilesReducer(state: IFilesState, action: IFilesAction): IFilesState {
    const newState = { ...state };
    newState.files = [...state.files];
    switch (action.type) {
        case FilesActionType.RefreshFilesFromTeams: {
            newState.files = newState.files.filter(f => f.parentId !== action.payload?.folder.fileId);
            let newFiles: IFileListItem[] = [];
            const payloadFiles = action.payload?.data as ISharepointFileResponse[];
            if (payloadFiles?.length > 0) {
                newFiles = payloadFiles.map(file => {
                    const item: IFileListItem = MapISharePointFileResponseToISharepointDocumentItem(file);
                    item.parentId = action.payload?.folder.fileId;
                    return item;
                });
            }

            newFiles.forEach(file => {
                const existingFile = state.files.find(f => f.fileId === file.fileId);
                if (existingFile) {
                    newState.files.push({
                        ...file,
                        parentId: action.payload?.folder.fileId,
                        caseNumber: existingFile.caseNumber,
                        documentNumber: existingFile.documentNumber,
                        caseConnection: existingFile.caseConnection,
                        documentConnection: existingFile.documentConnection,
                        caseTitle: existingFile.caseTitle,
                        documentTitle: existingFile.documentTitle,
                        documentStatus: existingFile.documentStatus,
                        archiveStatus: existingFile.archiveStatus,
                        packageType: existingFile.packageType,
                        archiveCount: existingFile.archiveCount,
                        totalCount: existingFile.totalCount,
                        isReadonly: existingFile.isReadonly,
                        IsCheckedout: existingFile.IsCheckedout,
                        isNotSupported: existingFile.isNotSupported,
                        isConnectedTo360: existingFile.isConnectedTo360,
                        isSyncFailed: existingFile.isSyncFailed,
                        fileRecno: existingFile.fileRecno,
                        archivingStatusChecked: existingFile.archivingStatusChecked,
                        syncConfig: existingFile.syncConfig
                    });
                } else {
                    newState.files.push(file);
                }
            });

            return newState;
        }
        case FilesActionType.RefreshArchivingStatus:
            (action.payload?.data as ISharepointFileResponse[]).forEach((sf) => {
                const index = newState.files.findIndex(x => x.fileId === sf.Id);
                newState.files[index] = {
                    ...newState.files[index],
                    caseNumber: sf.Case?.CaseNumber,
                    documentNumber: sf.Document?.DocumentNumber,
                    caseConnection: sf.Case?.LinkTo360,
                    documentConnection: sf.Document?.LinkTo360,
                    caseTitle: formatCaseTitle(sf.Case),
                    documentTitle: sf.Document?.DocumentNumber + ' - ' + sf.Document?.Title,
                    documentStatus: sf.Document?.DocumentStatus,
                    archiveStatus: sf.Document?.ArchiveStatus,
                    packageType: sf.Package?.Type,
                    archiveCount: sf.ArchiveCount,
                    totalCount: sf.TotalCount,
                    isReadonly: isReadonly(sf),
                    IsCheckedout: sf.IsCheckedout,
                    isNotSupported: sf.IsNotSupported,
                    isConnectedTo360: sf.IsConnectedTo360,
                    isSyncFailed: sf.IsSyncFailed,
                    fileRecno: sf.Recno,
                    syncConfig: sf.SyncConfig
                };
                if (Features.UseNewLoading) {
                    newState.files[index].archivingStatusChecked = true;
                }
            });

            return newState;
        case FilesActionType.AddImportedFiles: {
            const folderIndex = newState.files.findIndex(x => x.fileId === action.payload?.folder.fileId);
            (action.payload?.data as ISharepointFileResponse[]).filter(f => f.IsSuccessful).forEach((sf) => {
                newState.files.push({ ...MapISharePointFileResponseToISharepointDocumentItem(sf), parentId: action.payload?.folder.fileId });
                newState.files[folderIndex].totalCount = newState.files[folderIndex].totalCount as number + 1;
                newState.files[folderIndex].archiveCount = newState.files[folderIndex].archiveCount as number + 1;
            });

            return newState;
        }
        case FilesActionType.RemoveSyncConflict: {
            (action.payload?.data as IFileListItem[]).forEach((sf) => {
                const index = newState.files.findIndex(x => x.fileId === sf.fileId);
                newState.files[index] = {
                    ...newState.files[index],
                    isSyncFailed: false
                };
            });

            return newState;
        }
        case FilesActionType.SetArchivingStatusChecked: {
            (action.payload?.data as ISharepointFileResponse[]).forEach((sf) => {
                const index = newState.files.findIndex(x => x.fileId === sf.Id && !x.archivingStatusChecked);
                newState.files[index] = {
                    ...newState.files[index],
                    archivingStatusChecked: true
                };
            });

            return newState;
        }

        case FilesActionType.SetChannelAutomaticArchivingInfo: {
            const channelInfo = action.payload?.channelInfo;
            if (channelInfo) {
                const index = newState.files.findIndex(x => x.fileId === action.payload?.folder.fileId);
                newState.files[index] = {
                    ...newState.files[index],
                    caseNumber: channelInfo.Case?.CaseNumber,
                    caseTitle: formatCaseTitle(channelInfo.Case),
                    caseConnection: channelInfo.Case?.LinkTo360,
                    isReadonly: channelInfo.Case?.IsClosed === true,
                    syncConfig: channelInfo.SyncConfig
                };
            }

            return newState;
        }
        default:
            return state;
    }
}

export default FilesReducer;