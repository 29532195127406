import React, { useCallback } from 'react';
import TagComponent, { EnumTagComponentType } from '../common/TagComponent';
import { useFiles } from '../../context/FilesContext';
import { t } from 'i18next';

const ChannelArchivingTagContainer: React.FC = () => {
    const { channelRootFolder } = useFiles();

    const getChannelTagComponentType = useCallback(() => {
        if (channelRootFolder?.syncConfig?.SyncEnabled === true) {
            return channelRootFolder.isReadonly === true ? EnumTagComponentType.Disabled : EnumTagComponentType.Automatic;
        }

        return EnumTagComponentType.Manual;
    }, [channelRootFolder]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: 20 }}>
            <div style={{ marginBottom: 5 }}>{t('Channel archiving')}:</div>
            <TagComponent type={getChannelTagComponentType()} />
        </div>
    );
};

export default ChannelArchivingTagContainer;