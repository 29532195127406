import { FC, useCallback, useEffect, useState } from "react";
import { ICommonProps } from "../ICommonProps";
import { t } from "i18next";
import { CheckboxVisibility, ConstrainMode, DetailsListLayoutMode, DetailsRow, IColumn, IconButton, IDetailsRowProps, IRenderFunction, SelectionMode, ShimmeredDetailsList } from "@fluentui/react";
import { useTeams } from "../../context/TeamsContext";
import { useMeeting } from "../../context/MeetingContext";
import { IMessage } from "../../model/model";
import { Constants } from "../../helpers/Constants";
import { FormatDateTime } from "../../helpers/FormatDateTime";
import ExpandableRow from "./ExpandableRow";

interface IMeetingRecordingListProps extends ICommonProps {
    // use only for testing purposes
    skipViewportMeasures?: boolean;
}
const MeetingRecordingList: FC<IMeetingRecordingListProps> = (props) => {
    const { teamsState } = useTeams();
    const { meetingState } = useMeeting();
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [expandedRows, setExpandedRows] = useState<string[]>([]);

    const onRenderCreatedDateTime = useCallback((item: IMessage) => {
        return <span>{FormatDateTime.convert(item.createdDateTime)}</span>;
    }, []);

    const onRenderName = (item: IMessage) => {
        return <span>{meetingState.meeting.topic}</span>;
    };

    const onRenderLength = useCallback((item: IMessage) => {
        return <span>{FormatDateTime.convertDuration(item.eventDetail.callRecordingDuration)}</span>;
    }, []);

    const toggleRowExpansion = useCallback((id: string) => {
        if (expandedRows.includes(id)) {
            setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
        } else {
            setExpandedRows([...expandedRows, id]);
        }
    }, [expandedRows]);

    const onRenderExpand = useCallback((item: IMessage) => {
        return <IconButton
            iconProps={{ iconName: expandedRows.includes(item.Id) ? 'ChevronUp' : 'ChevronDown' }}
            data-testid="expandColumn"
            onClick={() => toggleRowExpansion(item.Id)}
        />;
    }, [expandedRows, toggleRowExpansion]);

    const columns: IColumn[] = [
        { key: 'createdDateTime', name: t('Date/Time'), minWidth: 80, maxWidth: 180, onRender: onRenderCreatedDateTime },
        { key: 'name', name: t('Name'), minWidth: 120, maxWidth: 500, onRender: onRenderName },
        { key: 'length', name: t('Length'), minWidth: 200, onRender: onRenderLength },
        { key: 'expandColumn', name: '', minWidth: 50, maxWidth: 50, onRender: onRenderExpand }
    ];

    const getEventDetails = useCallback(() => {
        const messages = [...meetingState.messages];
        const callRecordings = getCallRecordingEventMessageDetail(messages);
        setMessages(callRecordings);
    }, [meetingState.messages]);

    useEffect(() => {
        getEventDetails();
    }, [getEventDetails, meetingState.meeting]);

    const onRenderRow: IRenderFunction<IDetailsRowProps> = useCallback((rowProps, defaultRender) => {
        if (!rowProps || defaultRender === undefined) return null;

        const meessage = rowProps.item as IMessage;
        const isRowExpanded = expandedRows.includes(meessage.Id);
        return (
            <>
                <DetailsRow {...rowProps} />
                {isRowExpanded && (
                    <ExpandableRow event={meetingState.event} message={meessage} />
                )}
            </>
        );
    }, [expandedRows, meetingState.event]);

    return <ShimmeredDetailsList key={teamsState.userContext?.chat?.id}
        setKey={teamsState.userContext?.chat?.id}
        items={messages}
        columns={columns}
        checkboxVisibility={CheckboxVisibility.always}
        selectionMode={SelectionMode.multiple}
        enableShimmer={meetingState.isLoading}
        layoutMode={DetailsListLayoutMode.justified}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        checkButtonAriaLabel="Select row"
        ariaLabelForShimmer={t('Loading...')}
        selectionPreservedOnEmptyClick={true}
        constrainMode={ConstrainMode.unconstrained}
        onRenderRow={onRenderRow}
        skipViewportMeasures={props.skipViewportMeasures}
    />;
};

function getCallRecordingEventMessageDetail(messages: IMessage[]) {
    return messages.filter((message) => {
        if (message.eventDetail) {
            return (message.eventDetail['@odata.type'] === Constants.EventDetail.CallRecordingEventMessageDetail
                && message.eventDetail.callRecordingStatus === Constants.EventDetail.CallRecordingStatusSuccess);
        }
        return false;
    });
}

export default MeetingRecordingList;