import React, { useCallback } from 'react';
import { useFiles } from '../../context/FilesContext';
import { Link } from '@fluentui/react';
import { CombineUrl } from '../../helpers/UrlHelper';
import { useP360 } from '../../context/P360Context';
import { useTranslation } from 'react-i18next';

const ChannelAutomaticArchivingInfo: React.FC = () => {
    const { channelRootFolder } = useFiles();
    const { p360State } = useP360();
    const { t } = useTranslation();

    const getChannelInfo = useCallback(() => {
        if (channelRootFolder?.syncConfig?.SyncEnabled !== true) {
            return null;
        }

        const style = { marginLeft: 33 };
        const caseLink = <Link target="_blank" href={CombineUrl(p360State.p360Url, channelRootFolder.caseConnection as string)} underline={true}>{channelRootFolder.caseTitle}</Link>;
        const text = channelRootFolder.isReadonly === true
            ? t('Automatic archiving is disabled because the case is closed')
            : t('Automatic archiving is enabled to case');

        return <div style={style}>{text} {caseLink}</div>;
    }, [channelRootFolder?.caseConnection, channelRootFolder?.caseTitle, channelRootFolder?.isReadonly, channelRootFolder?.syncConfig?.SyncEnabled, p360State.p360Url, t]);

    return getChannelInfo();
};

export default ChannelAutomaticArchivingInfo;