import { FC } from "react";
import MeetingDescription from "./MeetingDescription";
import MeetingMetadata from "./MeetingMetadata";
import { classNames } from "../../styles/MergeStyleSets";
import { IEvent, IMessage } from "../../model/model";
import MeetingRecording from "./MeetingRecording";

interface IExpandableRow {
    event: IEvent;
    message: IMessage;
}

const ExpandableRow: FC<IExpandableRow> = (props) => {
    return <div style={{ backgroundColor: '#f3f2f1' }}>
        <div className={classNames.meetingViewRow}>
            <div className={classNames.meetingViewColumn}>
                <MeetingDescription event={props.event} />
            </div>
            <div className={classNames.meetingViewColumn}>
                <MeetingMetadata event={props.event} message={props.message} />
                <MeetingRecording message={props.message} />
            </div>
        </div>
    </div>;
};

export default ExpandableRow;