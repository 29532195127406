import { FC } from "react";
import { Features } from "../../config/Features";
import { classNames } from "../../styles/MergeStyleSets";
import { ICommonProps } from "../ICommonProps";
import { t } from "i18next";
import { Pivot, PivotItem } from "@fluentui/react";
import MeetingChat from "./MeetingChat";
import MeetingRecordingList from "./MeetingRecordingList";

const MeetingArchiving: FC<ICommonProps> = (props) => {
    if (Features.EnableMeetingArchiving) {
        return <Pivot>
            <PivotItem headerText={t('Archive Chat')}>
                <MeetingChat {...props} />
            </PivotItem>
            <PivotItem headerText={t('Archive Recording')}>
                <MeetingRecordingList {...props} />
            </PivotItem>
        </Pivot>;
    } else {
        return <blockquote className={classNames.errorBlockQuote}>
            <h3>{t('Meeting Archiving is not supported in this version of 360°')}</h3>
        </blockquote>;
    }
};

export default MeetingArchiving;