import { FC } from "react";
import { classNames, pivotStyles } from "../../styles/MergeStyleSets";
import { Pivot, PivotItem, Image } from "@fluentui/react";
import { t } from "i18next";
import MeetingAttachment from "./MeetingAttachment";
import { IEvent, IMessage } from "../../model/model";
import NoTranscriptIcon from '../../images/noTranscript.svg';
import MeetingAttendanceReport from "./MeetingAttendanceReport";
import TranscriptWebVTTDisplay from "./TranscriptWebVTTDisplay";

interface IMeetingMetadataProps {
    event: IEvent;
    message: IMessage;
}

const MeetingMetadata: FC<IMeetingMetadataProps> = (props) => {
    return <div className={classNames.meetingItemContainer} style={{ height: '375px' }}>
        <Pivot style={{ padding: '5px' }} linkFormat="tabs" styles={pivotStyles}>
            <PivotItem headerText={t('Files')} itemIcon="Document">
                <MeetingAttachment event={props.event} />
            </PivotItem>
            <PivotItem headerText={t('Attendees')} itemIcon="People">
                <MeetingAttendanceReport event={props.event} message={props.message} />
            </PivotItem>
            <PivotItem headerText={t('Transcript')} itemIcon="TextDocument">
                {
                    props.message.eventDetail.meetingTranscriptAndAttendanceReport?.transcript != null ?
                        <TranscriptWebVTTDisplay data={props.message.eventDetail.meetingTranscriptAndAttendanceReport.transcript.content} />
                        :
                        <div style={{ marginLeft: '20px', marginTop: '20px' }}>
                            <Image src={NoTranscriptIcon} alt={t('No transcript available')} width={128} height={128} />
                            <h2>{t('No transcript available')}</h2>
                        </div>
                }
            </PivotItem>
        </Pivot>
        <div className={classNames.meetingItemContainerContent}></div>
    </div>;
};

export default MeetingMetadata;