import { t } from "i18next";
import { FC } from "react";

export enum EnumTagComponentType {
    Disabled,
    Enabled,
    Automatic,
    Manual
}

export interface ITagComponentProps {
    type: EnumTagComponentType;
}

const TagComponent: FC<ITagComponentProps> = (props: ITagComponentProps) => {
    const { type } = props;
    let label = '';

    const tagStyles = {
        backgroundColor: '#efefef',
        color: '#000000',
        padding: '1px 15px 4px 15px',
        borderRadius: '10px',
        fontSize: '12px',
        display: 'inline-block',
    };
    switch (type) {
        case EnumTagComponentType.Disabled:
            label = t('Disabled');
            tagStyles.backgroundColor = '#efefef';
            tagStyles.color = '#999999';
            break;
        case EnumTagComponentType.Enabled:
            label = t('Enabled');
            break;
        case EnumTagComponentType.Automatic:
            label = t('Automatic');
            break;
        case EnumTagComponentType.Manual:
            label = t('Manual');
            break;
    }

    return label ? <span style={tagStyles}>{label}</span> : null;
};

export default TagComponent;