import { FC } from "react";
import { classNames } from "../../styles/MergeStyleSets";
import { IEvent } from "../../model/model";
import { t } from "i18next";

interface IMeetingDescription {
    event: IEvent
}

const MeetingDescription: FC<IMeetingDescription> = (props) => {
    return <div className={classNames.meetingItemContainer} style={{ height: '501px' }}>
        <div className={classNames.meetingItemContainerHeader}>{t('Meeting description')}</div>
        <div className={classNames.meetingItemContainerContent} dangerouslySetInnerHTML={{ __html: props.event.body.Content }}>
        </div>
    </div >;
};

export default MeetingDescription;