import { FC } from "react";
import { classNames } from "../../styles/MergeStyleSets";
import { IEvent, IMeetingAttachment } from "../../model/model";
import { FileType } from "../../helpers/FileType";

interface IMeetingAttachmentProps {
    event: IEvent;
}

const MeetingAttachment: FC<IMeetingAttachmentProps> = (props) => {

    const renderAttachment = (attachment: IMeetingAttachment) => {
        if (attachment.name) {
            const fileType = FileType.getfileType(attachment.name);
            return <div key={attachment.id} style={{ display: 'flex', padding: '10px', columnGap: '10px', border: '1px solid #cccbc8', margin: '5px', borderRadius: '5px' }}>
                <img src={fileType.url} alt={fileType.docType} width={16} height={16} />
                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{attachment.name}</span>
            </div>;
        }
    };

    return <div>
        <div className={classNames.meetingItemContainerHeader}></div>
        <div style={{ paddingLeft: '5px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '10px' }}>
                {props.event.attachments?.map((attachment, index) => (
                    renderAttachment(attachment)
                ))}
            </div>
        </div>
    </div>;
};

export default MeetingAttachment;