import { createContext, Dispatch, useContext, useEffect, useMemo, useReducer } from "react";
import { useTeams } from "./TeamsContext";
import { IEvent, IMeeting, ITeamServiceRequest } from "../model/model";
import { MapTeamsContextToITeamRequest } from "../helpers/Mapper";
import { DataProvider } from "../providers/DataProvider";
import MeetingReducer, { IMeetingAction, IMeetingState, MeetingActionType } from "./MeetingReducer";

export interface IMeetingContextType {
    meetingState: IMeetingState;
    dispatch: Dispatch<IMeetingAction>;
}

interface IMeetingContextProviderProps {
    children: React.ReactElement;
    dataProvider: DataProvider;
}

const MeetingContext = createContext<IMeetingContextType | undefined>(undefined);

const MeetingContextProvider = (props: Readonly<IMeetingContextProviderProps>): JSX.Element => {
    const { teamsState } = useTeams();
    const [meetingState, dispatch] = useReducer(MeetingReducer, { meeting: {} as IMeeting, event: {} as IEvent, messages: [], isLoading: false });

    useEffect(() => {
        async function getMeeting() {
            dispatch({
                type: MeetingActionType.MeetingData,
                payload: { isLoading: true }
            });
            const token = await teamsState.getAccessToken();
            const request: ITeamServiceRequest = {
                accessToken: token,
                teamRequest: MapTeamsContextToITeamRequest(teamsState.userContext)
            };
            props.dataProvider?.P360.getMeetingDetails(request).then(response => {
                if (response) {
                    if (response.data.messages.length > 0) {
                        dispatch({
                            type: MeetingActionType.MeetingData,
                            payload: { meeting: response.data.meeting, event: response.data.event, messages: response.data.messages, isLoading: false }
                        });
                    }
                    else {
                        dispatch({
                            type: MeetingActionType.MeetingData,
                            payload: { meeting: response.data.meeting, event: response.data.event, isLoading: false }
                        });
                    }
                }
            }).catch(error => {
                dispatch({
                    type: MeetingActionType.MeetingData,
                    payload: { isLoading: false }
                });
            });

        }
        getMeeting();
    }, [props.dataProvider?.P360, teamsState]);

    const meetingContext: IMeetingContextType = useMemo(() => ({
        meetingState: meetingState,
        dispatch: dispatch
    }), [meetingState]);

    return (
        <MeetingContext.Provider value={meetingContext}>
            {props.children}
        </MeetingContext.Provider>
    );
};

function useMeeting() {
    const context = useContext(MeetingContext);
    if (context === undefined) {
        throw new Error('useMeeting must be used within a MeetingProvider');
    }
    return context;
}

export { MeetingContext, MeetingContextProvider, useMeeting };