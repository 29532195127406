import { IEvent, IMeeting, IMessage } from "../model/model";
export enum MeetingActionType {
    MeetingData = "MEETING_DATA"
}

export interface IMeetingState {
    meeting: IMeeting;
    event: IEvent;
    messages: IMessage[];
    isLoading: boolean;
}

export interface IMeetingPayload {
    meeting?: IMeeting;
    event?: IEvent;
    messages?: IMessage[];
    isLoading?: boolean;
}

export interface IMeetingAction {
    type: MeetingActionType;
    payload?: IMeetingPayload
}

function MeetingReducer(state: IMeetingState, action: IMeetingAction): IMeetingState {
    const newState = { ...state };
    if (action.type === MeetingActionType.MeetingData) {
        newState.meeting = action.payload?.meeting as IMeeting;
        newState.event = action.payload?.event as IEvent;
        newState.messages = action.payload?.messages ?? [];
        newState.isLoading = action.payload?.isLoading ?? false;
        return newState;
    }
    return state;
}

export default MeetingReducer;