import { FC } from "react";
import { classNames } from "../../styles/MergeStyleSets";
import { ICommonProps } from "../ICommonProps";

const MeetingChat: FC<ICommonProps> = (props) => {
    return <blockquote className={classNames.errorBlockQuote}>
        <h3>Meeting Chat</h3>
    </blockquote>;
};

export default MeetingChat;