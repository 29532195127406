/* istanbul ignore file */

import { app, authentication, pages } from "@microsoft/teams-js";
import { IMsTeamsProvider } from "./IMsTeamsProvider";

export class MsTeamsProvider implements IMsTeamsProvider {
    initialize(): Promise<void> {
        return app.initialize();
    }
    notifySuccess(): void {
        app.notifySuccess();
    }
    notifyFailure(appInitializationFailedRequest: app.IFailedRequest): void {
        app.notifyFailure(appInitializationFailedRequest);
    }
    getSettings(): Promise<pages.InstanceConfig> {
        return pages.getConfig();
    }
    setSettings(instanceSettings: pages.InstanceConfig): Promise<void> {
        return pages.config.setConfig(instanceSettings);
    }
    setValidityState(validityState: boolean): void {
        pages.config.setValidityState(validityState);
    }
    getContext(): Promise<app.Context> {
        return app.getContext();
    }
    getAuthToken(authTokenRequest?: authentication.AuthTokenRequestParameters): Promise<string> {
        return authentication.getAuthToken(authTokenRequest);
    }
}