import { FC, useCallback, useState } from 'react';
import { AxiosResponse } from "axios";
import { ICommonPropsArchivePanel } from '../ICommonProps';
import { ArchiveFileOnExistingDocumentRequestParameter, EnumCase, ICaseDocumentRequest, IFileListItem, ISharepointFileResponse, ITeamRequest, ITeamServiceRequest, SetChannelAutomaticArchivingRequestParameter } from '../../model/model';
import { IPanelProps, IRenderFunction, IconButton, Panel, PanelType, Stack } from '@fluentui/react';
import { verticalGapStackTokens, itemAlignmentsStackTokens, itemStylesT, stackItemDocumentTitleStyles, stackItemDocumentSubTitleStyles, itemAlignmentsStackStyles, itemAlignmentsStackStyles2, itemAlignmentsStackStyles1 } from '../../styles/MergeStyleSets';
import ProgressControl from '../ProgressControl';
import { useBoolean } from '@fluentui/react-hooks';
import { t } from "i18next";
import CaseInformationView from '../panel/CaseInformationView';
import { FileType } from '../../helpers/FileType';
import { MapTeamsContextToITeamRequest } from '../../helpers/Mapper';
import { useTeams } from '../../context/TeamsContext';
import { useFiles } from '../../context/FilesContext';
import { Constants } from '../../helpers/Constants';
import { EnumArchiveAction } from '../fileList/CommandBarContainer';

interface ArchivePanelContainerProps extends ICommonPropsArchivePanel, IPanelProps {
    isOpen: boolean,
    selectedFiles: IFileListItem[],
    clearSelectedFiles?: () => void
}

const ArchivePanelContainer: FC<ArchivePanelContainerProps> = (props) => {
    const { teamsState } = useTeams();
    const { currentFolder, updateFilesArchiveStatus, channelRootFolder, setChannelAutomaticArchivingInfo } = useFiles();

    const loading = t('Loading...');
    const documentProgresText = t('Archiving files to 360...');
    const { dataProvider, selectedFiles, isOpen, isFileArchiveAction, onDismiss } = props;
    const [progressState, setProgressState] = useState({ loading: false, text: t('Loading...') });
    const [singleDocument, { setTrue: createSingleDocument, setFalse: createMultipleDocument }] = useBoolean(false);

    const getHeader = useCallback(() => {
        if (props.archiveAction === EnumArchiveAction.ChannelArchiving) {
            return t('Archive channel');
        }

        if (props.isFileArchiveAction) {
            return t('Archive to 360');
        }

        return t('Create 360° case connection');
    }, [props.archiveAction, props.isFileArchiveAction]);

    const getSubHeader = useCallback(() => {
        if (props.archiveAction === EnumArchiveAction.ChannelArchiving) {
            return t('Connect a channel to a case in 360° for automatic archiving');
        }

        if (props.isFileArchiveAction) {
            return t('Files will be stored on the document card');
        }

        return t('Connect a folder to a case in 360° for easy archiving');
    }, [props.archiveAction, props.isFileArchiveAction]);

    const getTeamsRequestData = useCallback(async (caseDocumentData?: ICaseDocumentRequest, fileName?: string): Promise<ITeamServiceRequest[]> => {
        const teamRequest: ITeamServiceRequest[] = [];
        const accessToken = await teamsState.getAccessToken();
        selectedFiles.forEach((sf) => {
            const teamsData: ITeamServiceRequest = {
                accessToken: accessToken,
                driveId: sf.fileDriveId as string,
                fileId: sf.fileId as string,
                teamRequest: MapTeamsContextToITeamRequest(teamsState.userContext),
                fileName: sf.fileName as string,
                caseDocumentRequest: caseDocumentData
            };
            teamRequest.push(teamsData);
        });
        return teamRequest;
    }, [selectedFiles, teamsState]);

    const closePanel = useCallback(() => {
        onDismiss && onDismiss();
        setProgressState({ loading: false, text: loading });
        createMultipleDocument();
    }, [onDismiss, loading, createMultipleDocument]);

    const finalizeAndClosePanel = useCallback(() => {
        props.clearSelectedFiles?.();
        closePanel();
    }, [closePanel, props]);

    const archiveFile = useCallback((teamRequest: ITeamServiceRequest, currentCount: number, totalCount: number) => {
        dataProvider?.P360?.archiveFilesAsSingleDocument(teamRequest).then((res) => {
            if (res.data) {
                updateFilesArchiveStatus(currentFolder as IFileListItem, [res.data]);
            }
            if (currentCount === totalCount - 1) {
                finalizeAndClosePanel();
            }
        }).catch(() => setProgressState({ loading: false, text: loading }));
    }, [dataProvider?.P360, updateFilesArchiveStatus, currentFolder, finalizeAndClosePanel, loading]);

    const saveArchiveFiles = useCallback(async (caseDocumentData: ICaseDocumentRequest) => {
        const teamRequest = await getTeamsRequestData(caseDocumentData);
        if (teamRequest.length === 0) return;
        setProgressState({ loading: true, text: documentProgresText });

        dataProvider?.P360?.archiveFilesAsSingleDocument(teamRequest[0]).then((res) => {
            if (res.data) {
                updateFilesArchiveStatus(currentFolder as IFileListItem, [res.data]);
                if (teamRequest.length === 1)
                    finalizeAndClosePanel();
                for (let i = 1; i < teamRequest.length; i++) {
                    //Creating single document with multiple files
                    if (singleDocument)
                        teamRequest[i].caseDocumentRequest = { ...teamRequest[i].caseDocumentRequest, caseNumber: res.data.Case.CaseNumber, documentNumber: res.data.Document.DocumentNumber };
                    else
                        teamRequest[i].caseDocumentRequest = { ...teamRequest[i].caseDocumentRequest, caseNumber: res.data.Case.CaseNumber, documentNumber: "", documentTitle: FileType.removeExtensionFromFileName(teamRequest[i].fileName)?.substring(0, Constants.DocumentTitleMaxLength) };
                    archiveFile(teamRequest[i], i, teamRequest.length);
                }
            }
        }).catch(() => setProgressState({ loading: false, text: loading }));
    }, [getTeamsRequestData, documentProgresText, dataProvider?.P360, updateFilesArchiveStatus, currentFolder, finalizeAndClosePanel, singleDocument, archiveFile, loading]);

    const updatePromiseResultsToFilesCtx = useCallback((results: PromiseSettledResult<AxiosResponse<ISharepointFileResponse>>[]) => {
        results.forEach((result) => {
            if (result.status === "fulfilled" && result.value.data) {
                updateFilesArchiveStatus(currentFolder as IFileListItem, [result.value.data]);
            }
        });
    }, [currentFolder, updateFilesArchiveStatus]);

    const saveCaseConnection = useCallback(async (caseDocumentData: ICaseDocumentRequest) => {
        const teamRequest = await getTeamsRequestData(caseDocumentData);
        if (teamRequest.length === 0) { closePanel(); return; }
        setProgressState({ loading: true, text: loading });

        if (caseDocumentData.selectionNewOrExisting !== EnumCase.ExistingCase) {
            const res = await dataProvider?.P360?.addConnectionToNewCase(teamRequest.splice(0, 1)[0]);
            if (res?.data) {
                updateFilesArchiveStatus(currentFolder as IFileListItem, [res.data]);
                teamRequest.forEach(r => r.caseDocumentRequest = { caseNumber: res.data.Case.CaseNumber });
            } else {
                finalizeAndClosePanel();
                return;
            }
            if (teamRequest.length === 0) { closePanel(); return; }
        }

        const promises: Promise<AxiosResponse<ISharepointFileResponse>>[] = [];
        teamRequest.forEach((tr) => {
            promises.push(dataProvider?.P360?.addConnectionToExistingCase(tr) as Promise<AxiosResponse<ISharepointFileResponse>>);
        });

        Promise.allSettled(promises).then((results) => {
            updatePromiseResultsToFilesCtx(results);
            finalizeAndClosePanel();
        });
    }, [getTeamsRequestData, loading, closePanel, dataProvider?.P360, updateFilesArchiveStatus, currentFolder, finalizeAndClosePanel, updatePromiseResultsToFilesCtx]);

    const saveChannelConnection = useCallback(async (caseDocumentData: ICaseDocumentRequest) => {
        setProgressState({ loading: true, text: loading });

        const request: SetChannelAutomaticArchivingRequestParameter = {
            AccessToken: await teamsState.getAccessToken(),
            TeamRequest: MapTeamsContextToITeamRequest(teamsState.userContext) as ITeamRequest,
            CaseDocumentRequest: caseDocumentData
        };

        dataProvider?.P360?.setChannelAutomaticArchiving(request).then((res) => {
            if (res.data) {
                setChannelAutomaticArchivingInfo(res.data);
            }
            finalizeAndClosePanel();
        }).catch(() => setProgressState({ loading: false, text: loading }));
    }, [dataProvider?.P360, finalizeAndClosePanel, loading, setChannelAutomaticArchivingInfo, teamsState]);

    const archiveFilesOnExistingDocument = useCallback(async (caseDocumentData: ICaseDocumentRequest) => {
        setProgressState({ loading: true, text: documentProgresText });
        const promises: Promise<AxiosResponse<ISharepointFileResponse>>[] = [];
        const request: ArchiveFileOnExistingDocumentRequestParameter = {
            AccessToken: await teamsState.getAccessToken(),
            DocumentNumber: caseDocumentData.documentNumber as string,
            TeamRequest: MapTeamsContextToITeamRequest(teamsState.userContext) as ITeamRequest,
            DriveId: "",
            FileId: "",
            FileName: ""
        };
        selectedFiles.forEach((f) => {
            request.DriveId = f.fileDriveId as string;
            request.FileId = f.fileId as string;
            request.FileName = f.fileName as string;
            promises.push(dataProvider?.P360.archiveFileOnExistingDocument(request) as Promise<AxiosResponse<ISharepointFileResponse>>);
        });

        Promise.allSettled(promises).then((results) => {
            updatePromiseResultsToFilesCtx(results);
            finalizeAndClosePanel();
        });

    }, [dataProvider?.P360, documentProgresText, finalizeAndClosePanel, selectedFiles, teamsState, updatePromiseResultsToFilesCtx]);

    const saveTo360ClickHandler = useCallback((caseDocumentData: ICaseDocumentRequest, archiveOnExistingDocument?: boolean) => {
        if (archiveOnExistingDocument) {
            archiveFilesOnExistingDocument(caseDocumentData);
        } else if (isFileArchiveAction)
            saveArchiveFiles(caseDocumentData);
        else if (props.archiveAction === EnumArchiveAction.ChannelArchiving) {
            saveChannelConnection(caseDocumentData);
        } else {
            saveCaseConnection(caseDocumentData);
        }
    }, [archiveFilesOnExistingDocument, isFileArchiveAction, props.archiveAction, saveArchiveFiles, saveCaseConnection, saveChannelConnection]);

    const onRenderNavigationContent: IRenderFunction<IPanelProps> = useCallback(
        (props, defaultRender) => (
            <Stack horizontal horizontalAlign="start" styles={itemAlignmentsStackStyles} tokens={itemAlignmentsStackTokens}>
                <Stack horizontal horizontalAlign="start" styles={itemAlignmentsStackStyles1}>
                    <span>
                        <img src='https://static2.sharepointonline.com/files/fabric/assets/item-types/16/folder.svg' style={itemStylesT} alt={t('Folder')} />
                    </span>
                </Stack>
                <Stack horizontal horizontalAlign="start" styles={itemAlignmentsStackStyles2}>
                    <Stack tokens={itemAlignmentsStackTokens}>
                        <Stack.Item align="auto" styles={stackItemDocumentTitleStyles}>
                            <span>{getHeader()}</span>
                        </Stack.Item>
                        <Stack.Item align="auto" styles={stackItemDocumentSubTitleStyles}>
                            <span>{getSubHeader()}</span>
                        </Stack.Item>
                    </Stack>
                </Stack>
                <Stack horizontal horizontalAlign="end" styles={itemAlignmentsStackStyles1}>
                    <Stack.Item align="auto" styles={stackItemDocumentTitleStyles}>
                        <IconButton iconProps={{ iconName: 'Cancel' }} title="Close" ariaLabel="Close" onClick={closePanel} />
                    </Stack.Item>
                </Stack>
            </Stack>
        ),
        [getHeader, getSubHeader, closePanel]
    );

    const getCurrentConnectedCase = useCallback(() => {
        if (props.archiveAction === EnumArchiveAction.ChannelArchiving) {
            return channelRootFolder?.caseNumber ?? "";
        }

        const connectedCase = selectedFiles?.length === 1 && selectedFiles[0].isFolder ? selectedFiles[0].caseNumber : undefined;
        return connectedCase ?? currentFolder?.caseNumber ?? "";
    }, [props.archiveAction, selectedFiles, currentFolder?.caseNumber, channelRootFolder?.caseNumber]);

    return <Panel type={PanelType.custom} customWidth="418px" isOpen={isOpen} onDismiss={closePanel}
        closeButtonAriaLabel={t('Close')} onRenderNavigation={onRenderNavigationContent} styles={{ content: { marginTop: '-10px' } }}>
        <Stack tokens={verticalGapStackTokens}>
            <ProgressControl visible={progressState.loading} label={progressState.text} />
            <CaseInformationView {...props}
                selectedCase={getCurrentConnectedCase()}
                singleDocumentHandler={createSingleDocument}
                multiDocumentHandler={createMultipleDocument}
                saveClickHandler={saveTo360ClickHandler}
                closeClickHandler={closePanel} />
        </Stack>
    </Panel>;
};

export default ArchivePanelContainer;