import { FC, useCallback, useEffect, useState } from "react";
import { classNames } from "../../styles/MergeStyleSets";
import { IAttendanceRecord, IEvent, IMessage, IMessageItem, IParticipant } from "../../model/model";
import { List } from "@fluentui/react";
import ProfilePhoto from "../chatArchiving/ProfilePhoto";
import { t } from "i18next";

interface IMeetingAttendanceReportProps {
    event: IEvent;
    message: IMessage;
}

const MeetingAttendanceReport: FC<IMeetingAttendanceReportProps> = (props) => {
    const [participants, setParticipants] = useState<IParticipant[]>([]);

    const onRenderParticipants = useCallback((item?: IParticipant, index?: number, isScrolling?: boolean) => {
        const participant: IMessageItem = { fromUser: item?.emailAddress.name };
        return <div style={{ display: 'flex' }}>
            <ProfilePhoto item={participant} />
            <div style={{ padding: '7px 0px 0px 10px' }}>[{item?.type}]</div>
        </div>;
    }, []);

    const onRenderAttendees = useCallback((item?: IAttendanceRecord, index?: number, isScrolling?: boolean) => {
        const participantIndex = participants.findIndex((attendee) => attendee.emailAddress.address === item?.emailAddress);
        if (participantIndex > 0) {
            return <div>
                <ProfilePhoto item={{ fromUser: participants[participantIndex].emailAddress.name }} />
            </div>;
        }
        if (props.event.organizer.emailAddress.address === item?.emailAddress) {
            return <div>
                <ProfilePhoto item={{ fromUser: props.event.organizer.emailAddress.name }} />
            </div>;
        }
    }, [participants, props.event.organizer.emailAddress.address, props.event.organizer.emailAddress.name]);

    useEffect(() => {
        const attendees = [...props.event.attendees];
        attendees.push({ type: 'Organizer', emailAddress: { name: props.event.organizer.emailAddress.name, address: props.event.organizer.emailAddress.address } });
        setParticipants(attendees);
    }, [props.event.attendees, props.event.organizer]);

    return <div style={{ overflow: 'auto' }}>
        <div className={classNames.meetingItemContainerHeader}></div>
        <div style={{ paddingLeft: '10px' }}>
            <div>
                <h3>{t('People')} ({participants.length})</h3>
                <List items={participants} onRenderCell={onRenderParticipants} />
            </div>
            <div>
                <h3>{t('Attendees')} ({props.message.eventDetail.meetingTranscriptAndAttendanceReport.attendanceReport.attendanceRecords.length})</h3>
                <List items={props.message.eventDetail.meetingTranscriptAndAttendanceReport.attendanceReport.attendanceRecords} onRenderCell={onRenderAttendees} />
            </div>
        </div>
    </div>;
};

export default MeetingAttendanceReport;