import { FC, useCallback, useState } from 'react';
import { ICommonPropsArchivePanel } from '../ICommonProps';
import { EnumCase, EnumUIConfiguration, EnumViewName, ICaseDocumentRequest, ISharepointDocumentItem } from '../../model/model';
import CaseView from './CaseView';
import { useBoolean } from '@fluentui/react-hooks';
import DocumentInformationView from './DocumentInformationView';
import ChangeCaseView from './ChangeCaseView';
import { useP360 } from '../../context/P360Context';

interface ICaseInformationViewProps extends ICommonPropsArchivePanel {
    selectedCase: string,
    selectedFiles: ISharepointDocumentItem[],
    singleDocumentHandler(): void,
    multiDocumentHandler(): void,
    saveClickHandler(caseData: ICaseDocumentRequest | undefined, archiveOnExistingDocument?: boolean): void,
    closeClickHandler(): void
}

const CaseInformationView: FC<ICaseInformationViewProps> = (props) => {
    const [showDocumentView, { setTrue: showDocument, setFalse: hideDocument }] = useBoolean(false);
    const [caseDocumentData, setCaseDocumentData] = useState<ICaseDocumentRequest>({ caseNumber: props.selectedCase, caseTitle: getSelectedFileName(props.selectedFiles), documentTitle: getSelectedFileName(props.selectedFiles) });
    const [viewName, setViewName] = useState<EnumViewName>(() => {
        if (props.selectedCase)
            return EnumViewName.ChangeCaseView;
        else
            return EnumViewName.CaseView;
    });
    const { p360State } = useP360();

    const continueClickHandler = useCallback((caseDocumentData: ICaseDocumentRequest, viewName: EnumViewName) => {
        if (caseDocumentData.selectionNewOrExisting === EnumCase.NewCase)
            caseDocumentData.caseNumber = "";

        setCaseDocumentData(caseDocumentData);
        setViewName(viewName);
        showDocument();
    }, [showDocument]);

    const previousClickHandler = useCallback((caseDocumentData: ICaseDocumentRequest) => {
        hideDocument();
        setCaseDocumentData(caseDocumentData);
    }, [hideDocument]);

    const uiConfiguration = p360State.backendCapabilities?.Configuration.UIConfiguration ?? EnumUIConfiguration.Normal;

    if (showDocumentView) {
        return <DocumentInformationView {...props} uiConguration={uiConfiguration} caseDocumentData={caseDocumentData} previousClickHandler={previousClickHandler} />;
    }

    if (props.isFileArchiveAction) {
        if (viewName === EnumViewName.CaseView) {
            return <CaseView {...props} uiConguration={uiConfiguration} caseDocumentData={caseDocumentData} continueClickHandler={continueClickHandler} />;
        }

        if (viewName === EnumViewName.ChangeCaseView) {
            return <ChangeCaseView {...props} uiConguration={uiConfiguration} caseDocumentData={caseDocumentData} continueClickHandler={continueClickHandler} />;
        }
        return <></>;
    }

    return <CaseView {...props} uiConguration={uiConfiguration} caseDocumentData={caseDocumentData} continueClickHandler={continueClickHandler} />;
};

function getSelectedFileName(files?: ISharepointDocumentItem[]): string {
    if (files && files.length > 0) {
        return files[0].fileName as string;
    }
    return '';
}

export default CaseInformationView;